<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Bienvenido a factura.green 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Ingresa tu correo y contraseña para poder ingresar a tu cuenta
          </b-card-text>
          
          <!-- ILL: 150122 se quita a alerta de login del tema
          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">Admin:</span> admin@demo.com | admin</small>
              </p>
              <p>
                <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert>-->

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
              <b-form-group
                label="Correo electrónico"
                label-for="login-email"
                :invalid-feedback="t('tr_maillogin')"
              >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    @change="setInvalidLogInMsg"
                    placeholder="usuario@dominio.com"
                  />
                </b-form-group>
              </validation-provider>

              <!-- forgot password -->
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|min:8"
                >
                <b-form-group
                :invalid-feedback="invalidLogInMsg"
                >
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password / Contraseña</label>
                    <b-link :to="{name:'auth-forgot-password'}">
                      <!--<small>¿Olvidaste tu contraseña?</small>-->
                    </b-link>
                  </div>
                    <b-input-group
                      class="input-group-merge"
                      :class="invalidLogInMsg ? 'is-invalid':null"
                      
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="invalidLogInMsg ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        @click="setInvalidLogInMsg"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <!--<small class="text-danger">{{ errors[0] }}</small>-->                  
              </b-form-group>
            </validation-provider>

              <!-- checkbox ILL:200522 SE ELIMINA ESTE CHECK-->
<!--
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Recordarme en este equipo
                </b-form-checkbox>
              </b-form-group>
-->
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Ingresar
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>¿Eres nuevo en factura.green? </span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;Crea una cuenta</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <!--<div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>-->

          <!-- social buttons -->
          <!--<div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>-->
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BFormInvalidFeedback, BAlert, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import axios from '@axios'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { getHomeRouteForLoggedInUser, getRoleForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const appLoading = document.getElementById('loading-bg');
const app = document.getElementById('app');
function toggleLoader(){
  app.style.display = app.style.display ? '' : 'none'
  appLoading.style.display = app.style.display ? '' : 'none'
}

export default {
  setup() { //ILL:220422 se agrega setup para la utilería de traductor

    const { t } = useI18nUtils()

    return {
      t,
    }
  },  
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],

data() {
    return {
      status: '',
      password: '', // ILL: 150122 seteo inicial de pass 
      userEmail: '', // ILL: 150122 seteo inicial de mail
      invalidLogInMsg: '',
      sideImg: require('@/assets/images/pages/fg.logo.svg'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    setInvalidLogInMsg(){
      this.invalidLogInMsg = '';
    },
    login() {
      
      toggleLoader();

      this.$refs.loginForm.validate().then(success => {
        if (success) {
          const auth = getAuth()
          signInWithEmailAndPassword(auth, this.userEmail, this.password).then(
            userCredential => {
              const tokenResponse = userCredential._tokenResponse
              
              axios
                .post('/s/start', tokenResponse )
                .then(response => {
                  const { session, tokenBundle } = response.data
                  
                  useJwt.setToken(session.accessToken)
                  useJwt.setRefreshToken(session.refreshToken)
                  localStorage.setItem('session', JSON.stringify(session))
                  localStorage.setItem('tokenBundle', JSON.stringify(tokenBundle) )
                  this.$ability.update(session.account.ability)

                  const route = getHomeRouteForLoggedInUser(session.account.role);

                  this.$router.replace(route)
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Bienvenido ${session.business.name}`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `Has ingresado correctamente como ${session.account.alias}.`,
                        },
                      })
                      toggleLoader();
                      axios
                      .post('/account/settings', {} )
                      .then(response => {
                          const expDate = response?.data?.businessSecurity?.certificates?.main?.notAfter
                          store.commit('csd-advisor/expiry', expDate)
                          const {isCsdLoaded, weeksLeft, date} = store.getters['csd-advisor/currentState']
                          if(isCsdLoaded && weeksLeft <1){
                            this.$bvModal.msgBoxOk(`${this.$t('CSD modal edate')} ${date}. ${this.$t('CSD modal advice')}`, {
                                title: this.$t('CSD modal title'),
                                size: 'sm',
                                buttonSize: 'sm',
                                okTitle: this.$t('CSD modal accept'),
                                okVariant: 'success',
                                headerClass: 'p-2 border-bottom-0',
                                footerClass: 'p-2 border-top-0',
                                centered: true
                              })
                              .then(value => {
                                this.boxTwo = value
                              })
                          }
                        })
                      })
                })
                .catch(error => {
                  console.error(error)
                  this.invalidLogInMsg = this.$t('wrong password');
                  toggleLoader();
                })
            })
            .catch(error => {
              // for security this line of code is commented
              // console.log('session.error',error.code, error.message);
              // if(error.code === "auth/user-not-found"){
              //   this.invalidLogInMsg = "El usuario no existe";
              // }
              this.invalidLogInMsg = this.$t('wrong password');
              toggleLoader();
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
